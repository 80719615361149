<template>
  <span class="password-strength-meter">
    &nbsp;
    <span :class="'progress-bar ' + cssClass" :style="'width: ' + score + '%;'">
      {{ message }}
    </span>
  </span>
</template>

<style lang="scss">
@import "@/scss/settings";

.password-strength-meter {
  display: block;
  width: 100%;
  position: relative;
  background-color: $light-gray;
  border: rem-calc(1) solid $medium-gray;

  .progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    white-space: nowrap;

    &.weak {
      background-color: map-get($foundation-palette, alert);
    }

    &.medium {
      background-color: map-get($foundation-palette, warning);
    }

    &.strong {
      background-color: map-get($foundation-palette, success);
    }

    &.very-strong {
      background-color: map-get($foundation-palette, primary);
    }
  }
}
</style>

<script>
import {PASSWORD_REQUIREMENTS} from "@/constants/graphql/storeConfig";

export default {
  name: 'PasswordMeter',
  props: ['password'],
  data() {
    return {
      passwordRequirements: {},
      messages: [
        "No password",
        "Weak",
        "Medium",
        "Strong",
        "Very strong"
      ]
    }
  },
  computed: {
    goal: function() {
      return parseInt(this.passwordRequirements.required_character_classes_number) + 1;
    },
    strength: function() {
      let self = this;
      let goal = self.goal;
      let score = 0;
      let charClasses = [
        /[a-z]+/,
        /[A-Z]+/,
        /[0-9]+/,
        /[^a-zA-Z0-9\s]/
      ];

      charClasses.forEach(function(pattern) {
        if (pattern.test(self.password)) {
          score++;
        }
      });

      if (score >= goal) {
        score = parseInt(self.passwordRequirements.required_character_classes_number);
      }

      if (self.password.length >= parseInt(self.passwordRequirements.minimum_password_length)) {
        score++;
      }

      return score;
    },
    score: function() {
      if(this.strength === 0) {
        return 0;
      }

      return this.strength / this.goal * 100
    },
    message: function() {
      if(this.strength === 0) {
        return this.messages[0];
      }

      let key = Math.floor((this.messages.length - 1) * this.score / 100);
      key = (key === 0) ? 1 : key;

      return this.messages[key];
    },
    cssClass: function() {
      return this.message.replace(" ", "-").toLowerCase();
    }
  },
  apollo: {
    passwordRequirements: {
      query: PASSWORD_REQUIREMENTS
    }
  }
}
</script>