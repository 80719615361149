<template>
  <div class="grid-container">
    <h1>Login</h1>

    <label>
      Email
      <input type="email" v-model="email" />
    </label>

    <label>
      Password
      <input type="password" v-model="password" />
    </label>

    <button class="button" @click="login">
      Login
    </button>
  </div>
</template>

<script>
import { CUSTOMER_TOKEN, CUSTOMER } from "@/constants/graphql/customer";
import { CUSTOMER_CART, MERGE_CARTS } from "@/constants/graphql/checkout/cart";

export default {
  name: 'Login',
  data() {
    return {
      email: "",
      password: ""
    }
  },
  methods: {
    login() {
      let self = this;

      this.$apollo.mutate({
        mutation: CUSTOMER_TOKEN,
        variables: {
          email: this.email,
          password: this.password
        }
      }).then(function(data) {
        self.$cookies.set('customer', data.data.generateCustomerToken.token, '');

        self.$apollo.query({
          query: CUSTOMER
        }).then(function(data) {
          self.$customer.customer = data.data.customer;
        });

        self.$apollo.query({
          query: CUSTOMER_CART
        }).then(function(data){
          self.$apollo.mutate({
            mutation: MERGE_CARTS,
            variables: {
              guest: localStorage.cart,
              customer: data.data.customerCart.id
            }
          }).then(function(data) {
            localStorage.cart = data.data.mergeCarts.id
            self.$cart.cart = data.data.mergeCarts;
          });
        });

        self.$router.push({path: "/customer/account"});
      });
    }
  }
}
</script>