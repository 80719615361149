<template>
  <div></div>
</template>

<script>
import {CART, CREATE_EMPTY_CART} from "@/constants/graphql/checkout/cart";

export default {
  name: 'Logout',
  template: "",
  mounted() {
    let self = this;
    this.$cookies.remove('customer');
    this.$customer.customer = {}

    this.$apollo.mutate({
      mutation: CREATE_EMPTY_CART
    }).then(function(data) {
      localStorage.cart = data.data.createEmptyCart;

      self.$apollo.query({
        query: CART,
        variables: {
          cartId: localStorage.cart
        }
      }).then(function(data) {
        self.$cart.cart = data.data.cart;
      });
    });

    this.$router.push({path: "/"})
  }
}
</script>