<template>
  <div>
    <component v-bind:is="currentView" />
  </div>
</template>

<script>
import Create from "@/views/customer/account/Create";
import Dashboard from "@/views/customer/account/Dashboard";
import Login from "@/views/customer/account/Login";
import Logout from "@/views/customer/account/Logout";

export default {
  name: 'Catchall',
  components: {
    Create,
    Dashboard,
    Login,
    Logout
  },
  data() {
    return {
      guestRoutes: [
        "/login",
        "/create"
      ]
    }
  },
  computed: {
    isGuest: function() {
      return !this.$cookies.get('customer')
    },
    route: function() {
      return this.$route.params.route
    },
    currentView: function() {
      let route = this.route.split('/').filter(function (str) {
        return str !== "";
      });

      if (route.length !== 0) {
        route[route.length - 1] = this.capitalizeFirstLetter(route[route.length - 1])
        return route[route.length - 1];
      } else {
        return "Dashboard";
      }
    }
  },
  methods: {
    capitalizeFirstLetter: function(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  },
  mounted() {
    if (this.isGuest) {
      if (!this.guestRoutes.includes(this.route)) {
        this.$router.push({path: "/customer/account/login"})
      }
    } else {
      if (this.guestRoutes.includes(this.route)) {
        this.$router.push({path: "/customer/account"})
      }
    }
  }
}
</script>