<template>
  <div class="grid-container">
    <h1>Register</h1>

    <h2>Personal Information</h2>
    <hr />

    <label>
      First name
      <input type="text" v-model="variables.firstname" />
    </label>

    <label>
      Last name
      <input type="text" v-model="variables.lastname" />
    </label>

    <h2>Sign-in Information</h2>
    <hr />

    <label>
      Email address
      <input type="email" v-model="variables.email" />
    </label>

    <label>
      Password
      <input :type="(showPassword) ? 'text' : 'password'" v-model="variables.password" />
      <PasswordMeter :password="variables.password" />
    </label>

    <label>
      Show password
      <input type="checkbox" v-model="showPassword" />
    </label>

    <label>
      Subscribe to mailing list
      <input type="checkbox" v-model="variables.is_subscribed" />
    </label>

    <button class="button" @click="createCustomer">
      Register
    </button>
  </div>
</template>

<script>
import {CREATE_CUSTOMER} from "@/constants/graphql/customer";
import PasswordMeter from "@/components/customer/PasswordMeter";

export default {
  name: 'Create',
  components: {PasswordMeter},
  data() {
    return {
      variables: {
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        is_subscribed: false
      },
      showPassword: false
    }
  },
  methods: {
    createCustomer() {
      let self = this;

      this.$apollo.mutate({
        mutation: CREATE_CUSTOMER,
        variables: {
          input: this.variables
        }
      }).then(function(){
        self.$router.push({path: "/customer/account/login"})
      });
    }
  }
}
</script>